import crypto from 'crypto'

const SECRET = process.env.COUNTRY_COOKIE_SECRET || 'supersecret'

function sign(value) {
  const hmac = crypto.createHmac('sha256', SECRET)
  hmac.update(value)
  return `${value}.${hmac.digest('hex')}`
}

function verifySignedCookie(signed) {
  if (!signed || typeof signed !== 'string') return null

  const [value, hash] = signed.split('.')
  if (!value || !hash) return null

  const hmac = crypto.createHmac('sha256', SECRET)
  hmac.update(value)
  const expected = hmac.digest('hex')

  return expected === hash ? value : null
}

const checkIpAddr = async (context) => {
  return await context.app.$axios
    .get('https://api.ipdata.co/?api-key=' + context.$config.geoLocationIp)
    .then((data) => data)
    .catch((e) => {
      console.error('GeoIP error', e)
    })
}

const checkAvailStores = async (context) => {
  const { data } = await context.app.$vsf.$magento.api.availableStores(
    { availableStores: 'availableStores' },
    {}
  )
  return data
}

const changeStore = (context, store_code, availStores) => {
  const newStoreUrl = '/' + store_code
  window.open(newStoreUrl, '_self')
}

const plugin = async (context) => {
  const raw = context.app.$cookies.get('ip-country')
  const verifiedCountry = verifySignedCookie(raw)

  if (verifiedCountry) return // ✅ Valid cookie — do nothing

  // No valid cookie → fetch geolocation
  const data = await checkIpAddr(context)
  if (!data || !data.data?.country_code) return

  const countryCode = data.data.country_code
  const signedCountry = sign(countryCode)

  context.app.$cookies.set('ip-country', signedCountry, {
    maxAge: 31536000, // 1 year
    path: '/',
    sameSite: 'lax'
  })

  const availStores = await checkAvailStores(context)

  switch (countryCode) {
    case 'NL':
      changeStore(context, 'nl', availStores)
      break
    case 'FR':
      changeStore(context, 'fr', availStores)
      break
    case 'DE':
      changeStore(context, 'de', availStores)
      break
    case 'ES':
      changeStore(context, 'es', availStores)
      break
    default:
      changeStore(context, 'en', availStores)
      break
  }
}

export default plugin
